import React from "react"
// import { Link } from "gatsby"
// import Button from "react-bootstrap/Button"
// import Icon from "../../Icon"

import "./index.scss"

const TimetableNotice = () => (
  <div className="row">
    <div className="col text-center">
      <div className="alert alert-primary timetable-alert">
        <p className="alert-heading">
          New Timetable starting on Monday 21st September
        </p>
        <p className="alert-text">Including our new Saturday Service</p>

        {/* <Link to="/covid-19">
          <Button bsPrefix="stu" size="sm">
            Learn More <Icon prefix="fas" name="angle-right" />
          </Button>
        </Link> */}
      </div>
    </div>
  </div>
)

export default TimetableNotice

import React from "react"
import Button from "react-bootstrap/Button"
import Icon from "../../components/Icon"

import Layout from "../../components/Layout"
import TimetableNotice from "../../components/Content/TimetableNotice"
import Heading from "../../components/Layout/heading"
import Spacer from "../../components/Layout/spacer"

import SEO from "../../components/SEO"

const TFNSW = (props) => (
  <Layout location={props.location}>
    <SEO title="TfNSW SSTS" description="TfNSW SSTS" />
    <TimetableNotice />
    <Heading title="TfNSW SSTS Online Application Links" />
    <div className="row page-content">
      <div className="col-12 text-center">
        <p className="sub-heading">For more information</p>
        <a
          href="https://apps.transport.nsw.gov.au/ssts/schoolTravelPasses#/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button variant="primary" bsPrefix="stu">
            Click Here <Icon prefix="fas" name="angle-right" />
          </Button>
        </a>
      </div>
    </div>
    <Spacer />
    <div className="row page-content">
      <div className="col-12 text-center">
        <p>For Transport for NSW (TfNSW) SSTS New Applications</p>
        <a
          href="https://ssts-apply.transport.nsw.gov.au/ApplySSTS/ApplySSTS.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button variant="primary" bsPrefix="stu">
            Apply Online <Icon prefix="fas" name="angle-right" />
          </Button>
        </a>
      </div>
    </div>
    <div className="row page-content">
      <div className="col-12 text-center">
        <p className="sub-heading">Or</p>
        <p>
          If the student already has a bus pass and their address, school, name
          has changed or you need to change transport operator
        </p>
        <a
          href="https://ssts-apply.transport.nsw.gov.au/ApplySSTS/UpdateEntitlement.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button variant="primary" bsPrefix="stu">
            Update Student Details <Icon prefix="fas" name="angle-right" />
          </Button>
        </a>
      </div>
    </div>
  </Layout>
)

export default TFNSW
